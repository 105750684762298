"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const SpotifyIcon_vue_1 = require("./SpotifyIcon.vue");
const YoutubeIcon_vue_1 = require("./YoutubeIcon.vue");
exports.default = vue_1.defineComponent({
    name: "MediaLinks",
    components: {
        SpotifyIcon: SpotifyIcon_vue_1.default,
        YoutubeIcon: YoutubeIcon_vue_1.default,
    },
    props: {
        youtube: String,
        spotify: String,
        category: String,
    },
    data() {
        const isMobile = window.innerWidth <= 414;
        return {
            isMobile,
        };
    },
    inject: ["handleYoutubeClick"],
});

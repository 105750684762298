"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.awardData = void 0;
exports.awardData = [
    {
        title: "A Little Messed Up",
        artist: "june",
        won: false,
        category: "MUSIC_VIDEO",
        image: "a_little_messed_up.jpg",
        youtube: "https://www.youtube.com/watch?v=IHoj7qvNmwk",
        spotify: "https://open.spotify.com/track/5iDaEbPX8Lq2evx3ZpAgQU?si=_4ML6zMiTGCbPwjVU91hjg",
    },
    {
        title: "GIRL LIKE ME",
        artist: "Black Eyed Peas",
        feat: "ft. Shakira",
        won: true,
        category: "MUSIC_VIDEO",
        image: "translation.jpg",
        youtube: "https://www.youtube.com/watch?v=vMLk_T0PPbk",
        spotify: "https://open.spotify.com/track/1zLBsSVxETQOfINOLYvT5m?si=FVz6Q_0xQKWsji3FfMQY5g",
    },
    {
        title: "Tick Tock",
        artist: "Clean Bandit",
        feat: "(feat. 24kGoldn)",
        won: false,
        category: "MUSIC_VIDEO",
        image: "tick_tock.jpg",
        youtube: "https://www.youtube.com/watch?v=yC8SPG2LwSA",
        spotify: "https://open.spotify.com/track/27u7t9d7ZQoyjsCROHuZJ3?si=wjBOQo5JT--HszETdfhfvQ",
    },
    {
        title: "You should be sad",
        artist: "halsey",
        won: false,
        category: "MUSIC_VIDEO",
        image: "you_should_be_sad.jpg",
        youtube: "https://www.youtube.com/watch?v=8nBFqZppIF0",
        spotify: "https://open.spotify.com/track/7lidXGPXPYLNThITAOTlkK?si=T3XvxqyiRUm3dP5uY9XsEw",
    },
    {
        title: "白日",
        artist: "King Gnu",
        won: false,
        category: "MUSIC_VIDEO",
        image: "hakujitsu.jpg",
        youtube: "https://www.youtube.com/watch?v=ony539T074w",
        spotify: "https://open.spotify.com/track/4UgUArf7laRrELMyEhEqS8?si=mgWkJmqbTnCOElzw2TlDJg",
    },
    {
        title: "Ambulance",
        artist: "june",
        won: false,
        category: "VOCAL_PERFORMANCE",
        image: "ambulance.jpg",
        youtube: "https://www.youtube.com/watch?v=bk7-6CPJ7y0",
        spotify: "https://open.spotify.com/track/6Hg0gJtvyhKIVuy7qp0cRJ?si=pjQsC0u1TdC4QHmguhOZpA",
    },
    {
        title: "Closer",
        artist: "The Chainsmokers",
        feat: "ft. Halsey",
        won: false,
        category: "VOCAL_PERFORMANCE",
        image: "closer.jpg",
        youtube: "https://www.youtube.com/watch?v=0zGcUoRlhmw",
        spotify: "https://open.spotify.com/track/7BKLCZ1jbUBVqRi2FVlTVw?si=BJB70DHeSSGfYf61d8FWYA",
    },
    {
        title: "Godzilla",
        artist: "Eminem",
        feat: "(feat. Juice WRLD)",
        won: false,
        category: "VOCAL_PERFORMANCE",
        image: "godzilla.jpg",
        youtube: "https://www.youtube.com/watch?v=r_0JjYUe5jo",
        spotify: "https://open.spotify.com/track/7FIWs0pqAYbP91WWM0vlTQ?si=pKE617MdQcurApvmraSrjQ",
    },
    {
        title: "You should be sad",
        artist: "Halsey",
        won: true,
        category: "VOCAL_PERFORMANCE",
        image: "you_should_be_sad.jpg",
        youtube: "https://www.youtube.com/watch?v=8nBFqZppIF0",
        spotify: "https://open.spotify.com/track/7lidXGPXPYLNThITAOTlkK?si=T3XvxqyiRUm3dP5uY9XsEw",
    },
    {
        title: "白日",
        artist: "King Gnu",
        won: false,
        category: "VOCAL_PERFORMANCE",
        image: "hakujitsu.jpg",
        youtube: "https://www.youtube.com/watch?v=ony539T074w",
        spotify: "https://open.spotify.com/track/4UgUArf7laRrELMyEhEqS8?si=mgWkJmqbTnCOElzw2TlDJg",
    },
    {
        title: "Ambulance",
        artist: "june",
        won: false,
        category: "LYRICS",
        image: "ambulance.jpg",
        youtube: "https://www.youtube.com/watch?v=bk7-6CPJ7y0",
        spotify: "https://open.spotify.com/track/6Hg0gJtvyhKIVuy7qp0cRJ?si=pjQsC0u1TdC4QHmguhOZpA",
    },
    {
        title: "A Little Messed Up",
        artist: "june",
        won: false,
        category: "LYRICS",
        image: "a_little_messed_up.jpg",
        youtube: "https://www.youtube.com/watch?v=IHoj7qvNmwk",
        spotify: "https://open.spotify.com/track/5iDaEbPX8Lq2evx3ZpAgQU?si=_4ML6zMiTGCbPwjVU91hjg",
    },
    {
        title: "Remember The Name",
        artist: "Ed Sheeran",
        feat: "(feat. Eminem & 50 cent)",
        won: true,
        category: "LYRICS",
        image: "remember_the_name.jpg",
        youtube: "https://www.youtube.com/watch?v=ZX3B953Rc0I",
        spotify: "https://open.spotify.com/track/0AtP8EkGPn6SwxKDaUuXec?si=yW5pV445SRG0KUAvumUPiA",
    },
    {
        title: "Try Everything",
        artist: "Shakira",
        won: false,
        category: "LYRICS",
        image: "try_everything.jpg",
        youtube: "https://www.youtube.com/watch?v=c6rP-YP4c5I",
        spotify: "https://open.spotify.com/track/0VCJ5YjphljOf1YRcPp08v?si=QzlorFdhR3y9ct5b1VUgaQ",
    },
    {
        title: "You should be sad",
        artist: "Halsey",
        won: false,
        category: "LYRICS",
        image: "you_should_be_sad.jpg",
        youtube: "https://www.youtube.com/watch?v=8nBFqZppIF0",
        spotify: "https://open.spotify.com/track/7lidXGPXPYLNThITAOTlkK?si=T3XvxqyiRUm3dP5uY9XsEw",
    },
    {
        title: "Closer",
        artist: "The Chainsmokers",
        feat: "ft. Halsey",
        won: false,
        category: "ACCOMPANIMENT",
        image: "closer.jpg",
        youtube: "https://www.youtube.com/watch?v=0zGcUoRlhmw",
        spotify: "https://open.spotify.com/track/7BKLCZ1jbUBVqRi2FVlTVw?si=BJB70DHeSSGfYf61d8FWYA",
    },
    {
        title: "Lovesick Girls",
        artist: "BLACKPINK",
        won: false,
        category: "ACCOMPANIMENT",
        image: "lovesick_girls.jpg",
        youtube: "https://www.youtube.com/watch?v=dyRsYk0LyA8",
        spotify: "https://open.spotify.com/track/4Vhvlhomv8fTp2e6I4XTpe?si=Usq2vDjoSCWShezp2Fnm1g",
    },
    {
        title: "Tick Tock",
        artist: "Clean Bandit",
        feat: "(feat. 24kGoldn)",
        won: false,
        category: "ACCOMPANIMENT",
        image: "tick_tock.jpg",
        youtube: "https://www.youtube.com/watch?v=yC8SPG2LwSA",
        spotify: "https://open.spotify.com/track/27u7t9d7ZQoyjsCROHuZJ3?si=wjBOQo5JT--HszETdfhfvQ",
    },
    {
        title: "You should be sad",
        artist: "Halsey",
        won: true,
        category: "ACCOMPANIMENT",
        image: "you_should_be_sad.jpg",
        youtube: "https://www.youtube.com/watch?v=8nBFqZppIF0",
        spotify: "https://open.spotify.com/track/7lidXGPXPYLNThITAOTlkK?si=T3XvxqyiRUm3dP5uY9XsEw",
    },
    {
        title: "白日",
        artist: "King Gnu",
        won: false,
        category: "ACCOMPANIMENT",
        image: "hakujitsu.jpg",
        youtube: "https://www.youtube.com/watch?v=ony539T074w",
        spotify: "https://open.spotify.com/track/4UgUArf7laRrELMyEhEqS8?si=mgWkJmqbTnCOElzw2TlDJg",
    },
    {
        title: "GIRL LIKE ME",
        artist: "Black Eyed Peas",
        feat: "ft. Shakira",
        won: false,
        category: "COMPOSITION",
        image: "translation.jpg",
        youtube: "https://www.youtube.com/watch?v=vMLk_T0PPbk",
        spotify: "https://open.spotify.com/track/1zLBsSVxETQOfINOLYvT5m?si=FVz6Q_0xQKWsji3FfMQY5g",
    },
    {
        title: "Him & I",
        artist: "G-Eazy, Halsey",
        won: false,
        category: "COMPOSITION",
        image: "him_and_i.jpg",
        youtube: "https://www.youtube.com/watch?v=SA7AIQw-7Ms",
        spotify: "https://open.spotify.com/track/5k38wzpLb15YgncyWdTZE4?si=ZN6Q9BQvSL6IvllmVoJLYg",
    },
    {
        title: "Lovesick Girls",
        artist: "BLACKPINK",
        won: true,
        category: "COMPOSITION",
        image: "lovesick_girls.jpg",
        youtube: "https://www.youtube.com/watch?v=dyRsYk0LyA8",
        spotify: "https://open.spotify.com/track/4Vhvlhomv8fTp2e6I4XTpe?si=Usq2vDjoSCWShezp2Fnm1g",
    },
    {
        title: "Me Gusta",
        artist: "Anuel AA, Shakira",
        won: false,
        category: "COMPOSITION",
        image: "me_gusta.jpg",
        youtube: "https://www.youtube.com/watch?v=mf-UJ32PJgU",
        spotify: "https://open.spotify.com/track/5Xhqh4lwJPtMUTsdBztN1a?si=ua-yycnUSWe5cVhPzSvk0Q",
    },
    {
        title: "THE BADDEST",
        artist: "K/DA",
        won: false,
        category: "COMPOSITION",
        image: "the_baddest.jpg",
        youtube: "https://www.youtube.com/watch?v=RkID8_gnTxw",
        spotify: "https://open.spotify.com/track/2V4Fx72svQRxrFvNT1eq5f?si=JyQIO2QxSgy6VAr1HAy85g",
    },
    {
        artist: "Ed Sheeran",
        won: false,
        category: "ARTIST",
        image: "ed_sheeran.jpg",
        youtube: "https://www.youtube.com/user/EdSheeran",
        spotify: "https://open.spotify.com/artist/6eUKZXaKkcviH0Ku9w2n3V?si=jZg5T942SIuoVGLKZP_cMg",
    },
    {
        artist: "Eminem",
        won: false,
        category: "ARTIST",
        image: "eminem.jpg",
        youtube: "https://www.youtube.com/user/EminemMusic",
        spotify: "https://open.spotify.com/artist/7dGJo4pcD2V6oG8kP0tJRR?si=Zggwah-fS3mfC7ZPyQOCKQ",
    },
    {
        artist: "Halsey",
        won: true,
        category: "ARTIST",
        image: "halsey.jpg",
        youtube: "https://www.youtube.com/user/iamhalsey",
        spotify: "https://open.spotify.com/artist/26VFTg2z8YR0cCuwLzESi2?si=04Z_MjcTSjS3DeoOrY-Tsg",
    },
    {
        artist: "june",
        won: false,
        category: "ARTIST",
        image: "june.jpg",
        youtube: "https://www.youtube.com/channel/UCVN0FO3W5ekwjUB2gOICIyA",
        spotify: "https://open.spotify.com/artist/4HPbe1kKBultculBbxH66X?si=_LU-y1MiRASHV3GhgZZVJw",
    },
    {
        artist: "Shakira",
        won: false,
        category: "ARTIST",
        image: "shakira.jpg",
        youtube: "https://www.youtube.com/user/shakira",
        spotify: "https://open.spotify.com/artist/0EmeFodog0BfCgMzAIvKQp?si=QZtiwg8RQaSVXPeo13G7mQ",
    },
    {
        title: "A Little Messed Up",
        artist: "june",
        won: false,
        category: "NEW_RECORD",
        image: "a_little_messed_up.jpg",
        youtube: "https://www.youtube.com/watch?v=IHoj7qvNmwk",
        spotify: "https://open.spotify.com/track/5iDaEbPX8Lq2evx3ZpAgQU?si=_4ML6zMiTGCbPwjVU91hjg",
    },
    {
        title: "Ambulance",
        artist: "june",
        won: false,
        category: "NEW_RECORD",
        image: "ambulance.jpg",
        youtube: "https://www.youtube.com/watch?v=bk7-6CPJ7y0",
        spotify: "https://open.spotify.com/track/6Hg0gJtvyhKIVuy7qp0cRJ?si=pjQsC0u1TdC4QHmguhOZpA",
    },
    {
        title: "Lovesick Girls",
        artist: "BLACKPINK",
        won: false,
        category: "NEW_RECORD",
        image: "lovesick_girls.jpg",
        youtube: "https://www.youtube.com/watch?v=dyRsYk0LyA8",
        spotify: "https://open.spotify.com/track/4Vhvlhomv8fTp2e6I4XTpe?si=Usq2vDjoSCWShezp2Fnm1g",
    },
    {
        title: "Me Gusta",
        artist: "Anuel AA, Shakira",
        won: false,
        category: "NEW_RECORD",
        image: "me_gusta.jpg",
        youtube: "https://www.youtube.com/watch?v=mf-UJ32PJgU",
        spotify: "https://open.spotify.com/track/5Xhqh4lwJPtMUTsdBztN1a?si=ua-yycnUSWe5cVhPzSvk0Q",
    },
    {
        title: "You should be sad",
        artist: "Halsey",
        won: true,
        category: "NEW_RECORD",
        image: "you_should_be_sad.jpg",
        youtube: "https://www.youtube.com/watch?v=8nBFqZppIF0",
        spotify: "https://open.spotify.com/track/7lidXGPXPYLNThITAOTlkK?si=T3XvxqyiRUm3dP5uY9XsEw",
    },
    {
        title: "Ambulance",
        artist: "june",
        won: false,
        category: "RECORD",
        image: "ambulance.jpg",
        youtube: "https://www.youtube.com/watch?v=bk7-6CPJ7y0",
        spotify: "https://open.spotify.com/track/6Hg0gJtvyhKIVuy7qp0cRJ?si=pjQsC0u1TdC4QHmguhOZpA",
    },
    {
        title: "Him & I",
        artist: "G-Eazy, Halsey",
        won: true,
        category: "RECORD",
        image: "him_and_i.jpg",
        youtube: "https://www.youtube.com/watch?v=SA7AIQw-7Ms",
        spotify: "https://open.spotify.com/track/5k38wzpLb15YgncyWdTZE4?si=ZN6Q9BQvSL6IvllmVoJLYg",
    },
    {
        title: "Lovesick Girls",
        artist: "BLACKPINK",
        won: false,
        category: "RECORD",
        image: "lovesick_girls.jpg",
        youtube: "https://www.youtube.com/watch?v=dyRsYk0LyA8",
        spotify: "https://open.spotify.com/track/4Vhvlhomv8fTp2e6I4XTpe?si=Usq2vDjoSCWShezp2Fnm1g",
    },
    {
        title: "Me Gusta",
        artist: "Anuel AA, Shakira",
        won: false,
        category: "RECORD",
        image: "me_gusta.jpg",
        youtube: "https://www.youtube.com/watch?v=mf-UJ32PJgU",
        spotify: "https://open.spotify.com/track/5Xhqh4lwJPtMUTsdBztN1a?si=ua-yycnUSWe5cVhPzSvk0Q",
    },
    {
        title: "You should be sad",
        artist: "Halsey",
        won: false,
        category: "RECORD",
        image: "you_should_be_sad.jpg",
        youtube: "https://www.youtube.com/watch?v=8nBFqZppIF0",
        spotify: "https://open.spotify.com/track/7lidXGPXPYLNThITAOTlkK?si=T3XvxqyiRUm3dP5uY9XsEw",
    },
];

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = vue_1.defineComponent({
    name: "YoutubeModal",
    data() {
        return {
            show: false,
            url: "",
        };
    },
    provide() {
        const handleYoutubeClick = (url) => {
            this.url = url.replace("/watch?v=", "/embed/");
            this.show = true;
            this.$forceUpdate();
        };
        return { handleYoutubeClick };
    },
    methods: {
        hideModal() {
            this.show = false;
        },
    },
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const enumConstants_1 = require("../constants/enumConstants");
const duration = 300;
exports.default = vue_1.defineComponent({
    name: "PageScroller",
    mounted() {
        this.windowHeight = window.innerHeight;
        this.stepHeight = this.windowHeight / 50;
        this.scroller = document.querySelector(".scroller");
        this.pageYs = Array(enumConstants_1.TOTAL_PAGES)
            .fill(0)
            .map((_, index) => index * this.windowHeight);
    },
    destroyed() {
        this.scroller?.removeEventListener("scroll", this.handleScroll);
    },
    data() {
        return {
            windowHeight: window.innerHeight,
            stepHeight: 0,
            scroller: document.querySelector(".scroller"),
            isAutoScrolling: false,
            currentY: 0,
            pageYs: [],
            currentPage: 0,
            nextPage: 1,
            isWin: navigator.platform.includes("Win"),
            prevTouchY: undefined,
            scrollStartTime: undefined,
            direction: undefined,
        };
    },
    methods: {
        handleScroll() {
            if (!this.scroller) {
                return;
            }
            this.currentY = Math.ceil(this.scroller.scrollTop);
            // start
            if (!this.isAutoScrolling) {
                if (this.currentY > this.pageYs[this.currentPage]) {
                    this.nextPage = this.currentPage + 1;
                    this.direction = 1;
                }
                if (this.currentY < this.pageYs[this.currentPage]) {
                    this.nextPage = this.currentPage - 1;
                    this.direction = -1;
                }
                if (this.pageYs[this.nextPage] === undefined ||
                    this.nextPage === this.currentPage) {
                    return;
                }
                this.isAutoScrolling = true;
                window.requestAnimationFrame(this.scrollTo);
            }
        },
        handleTouchMove(e) {
            if (!this.scroller) {
                return;
            }
            const currentTouchY = e.touches[0].screenY;
            // start
            if (!this.isAutoScrolling) {
                if (this.prevTouchY && this.prevTouchY < currentTouchY) {
                    if (this.currentPage === 0) {
                        this.prevTouchY = undefined;
                        return;
                    }
                    console.log(this.prevTouchY, currentTouchY);
                    this.nextPage = this.currentPage - 1;
                    this.direction = -1;
                }
                if (this.prevTouchY && this.prevTouchY > currentTouchY) {
                    if (this.currentPage === enumConstants_1.TOTAL_PAGES - 1) {
                        this.prevTouchY = undefined;
                        return;
                    }
                    this.nextPage = this.currentPage + 1;
                    this.direction = 1;
                }
                this.prevTouchY = currentTouchY;
                if (this.direction === undefined) {
                    return;
                }
                this.isAutoScrolling = true;
                window.requestAnimationFrame(this.scrollTo);
            }
        },
        scrollTo(timestamp) {
            if (!this.scroller || !this.direction) {
                return;
            }
            if (this.scrollStartTime === undefined) {
                this.scrollStartTime = timestamp;
            }
            const elapsedTime = timestamp - this.scrollStartTime;
            const progress = Math.min(elapsedTime / duration, 1);
            this.scroller.scrollTop =
                this.pageYs[this.currentPage] +
                    this.stepHeight * progress * 50 * this.direction;
            if (elapsedTime < duration) {
                window.requestAnimationFrame(this.scrollTo);
            }
            else {
                this.scrollStartTime = undefined;
                this.prevTouchY = undefined;
                this.currentPage = this.nextPage;
                this.direction = undefined;
                this.isAutoScrolling = false;
            }
        },
    },
    provide() {
        return { height: this.windowHeight };
    },
});

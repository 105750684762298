"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const GlobalStyle_vue_1 = require("./components/GlobalStyle.vue");
const GlobalLayout_vue_1 = require("./components/GlobalLayout.vue");
const TopPage_vue_1 = require("./pages/TopPage.vue");
const AwardPage_vue_1 = require("./pages/AwardPage.vue");
const AwardPageFooter_vue_1 = require("./components/AwardPageFooter.vue");
const PageScroller_vue_1 = require("./components/PageScroller.vue");
const YoutubeModal_vue_1 = require("./components/YoutubeModal.vue");
const App = vue_1.defineComponent({
    name: "App",
    components: {
        GlobalStyle: GlobalStyle_vue_1.default,
        GlobalLayout: GlobalLayout_vue_1.default,
        TopPage: TopPage_vue_1.default,
        AwardPage: AwardPage_vue_1.default,
        AwardPageFooter: AwardPageFooter_vue_1.default,
        PageScroller: PageScroller_vue_1.default,
        YoutubeModal: YoutubeModal_vue_1.default,
    },
});
exports.default = App;

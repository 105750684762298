"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = vue_1.defineComponent({
    name: "AwardTitle",
    props: {
        category: { type: Object, required: true },
    },
    computed: {
        title() {
            return this.category.replace("_", " ").toUpperCase();
        },
    },
});

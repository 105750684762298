"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const MediaLinks_vue_1 = require("./MediaLinks.vue");
const NomineeImage_vue_1 = require("./NomineeImage.vue");
exports.default = vue_1.defineComponent({
    name: "NomineeCard",
    components: {
        MediaLinks: MediaLinks_vue_1.default,
        NomineeImage: NomineeImage_vue_1.default,
    },
    props: {
        nominee: {
            type: Object,
            required: true,
        },
        category: {
            type: String,
            required: true,
        },
    },
    data() {
        const isMobile = window.innerWidth <= 414;
        return { isMobile };
    },
    inject: ["handleYoutubeClick"],
});

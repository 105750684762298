"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const awardData_1 = require("../constants/awardData");
const AwardTitle_vue_1 = require("../components/AwardTitle.vue");
const NomineeCard_vue_1 = require("../components/NomineeCard.vue");
const SiteFooter_vue_1 = require("../components/SiteFooter.vue");
exports.default = vue_1.defineComponent({
    name: "AwardPage",
    components: {
        AwardTitle: AwardTitle_vue_1.default,
        NomineeCard: NomineeCard_vue_1.default,
        SiteFooter: SiteFooter_vue_1.default,
    },
    props: {
        category: { type: Object },
    },
    inject: ["height"],
    computed: {
        nominees() {
            return awardData_1.awardData.filter((a) => a.category === this.category);
        },
    },
});

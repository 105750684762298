"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = vue_1.defineComponent({
    name: "AwardPageFooter",
    data() {
        return {
            isSpreaded: false,
        };
    },
    methods: {
        clickSpread() {
            this.isSpreaded = !this.isSpreaded;
        },
    },
    inject: ["height"],
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = vue_1.defineComponent({
    name: "NomineeImage",
    props: {
        src: { type: String, required: true },
        won: { type: Boolean, required: true },
    },
    computed: {
        src2x() {
            return this.src.replace(".jpg", "@2x.jpg");
        },
        src3x() {
            return this.src.replace(".jpg", "@3x.jpg");
        },
    },
});
